@charset "UTF-8";
// Ionicons Common CSS
// --------------------------

.ionicons,
.ion-ios-add:before,
.ion-ios-add-circle:before,
.ion-ios-add-circle-outline:before,
.ion-ios-add-outline:before,
.ion-ios-alarm:before,
.ion-ios-alarm-outline:before,
.ion-ios-albums:before,
.ion-ios-albums-outline:before,
.ion-ios-alert:before,
.ion-ios-alert-outline:before,
.ion-ios-american-football:before,
.ion-ios-american-football-outline:before,
.ion-ios-analytics:before,
.ion-ios-analytics-outline:before,
.ion-ios-aperture:before,
.ion-ios-aperture-outline:before,
.ion-ios-apps:before,
.ion-ios-apps-outline:before,
.ion-ios-appstore:before,
.ion-ios-appstore-outline:before,
.ion-ios-archive:before,
.ion-ios-archive-outline:before,
.ion-ios-arrow-back:before,
.ion-ios-arrow-back-outline:before,
.ion-ios-arrow-down:before,
.ion-ios-arrow-down-outline:before,
.ion-ios-arrow-dropdown:before,
.ion-ios-arrow-dropdown-circle:before,
.ion-ios-arrow-dropdown-circle-outline:before,
.ion-ios-arrow-dropdown-outline:before,
.ion-ios-arrow-dropleft:before,
.ion-ios-arrow-dropleft-circle:before,
.ion-ios-arrow-dropleft-circle-outline:before,
.ion-ios-arrow-dropleft-outline:before,
.ion-ios-arrow-dropright:before,
.ion-ios-arrow-dropright-circle:before,
.ion-ios-arrow-dropright-circle-outline:before,
.ion-ios-arrow-dropright-outline:before,
.ion-ios-arrow-dropup:before,
.ion-ios-arrow-dropup-circle:before,
.ion-ios-arrow-dropup-circle-outline:before,
.ion-ios-arrow-dropup-outline:before,
.ion-ios-arrow-forward:before,
.ion-ios-arrow-forward-outline:before,
.ion-ios-arrow-round-back:before,
.ion-ios-arrow-round-back-outline:before,
.ion-ios-arrow-round-down:before,
.ion-ios-arrow-round-down-outline:before,
.ion-ios-arrow-round-forward:before,
.ion-ios-arrow-round-forward-outline:before,
.ion-ios-arrow-round-up:before,
.ion-ios-arrow-round-up-outline:before,
.ion-ios-arrow-up:before,
.ion-ios-arrow-up-outline:before,
.ion-ios-at:before,
.ion-ios-at-outline:before,
.ion-ios-attach:before,
.ion-ios-attach-outline:before,
.ion-ios-backspace:before,
.ion-ios-backspace-outline:before,
.ion-ios-barcode:before,
.ion-ios-barcode-outline:before,
.ion-ios-baseball:before,
.ion-ios-baseball-outline:before,
.ion-ios-basket:before,
.ion-ios-basket-outline:before,
.ion-ios-basketball:before,
.ion-ios-basketball-outline:before,
.ion-ios-battery-charging:before,
.ion-ios-battery-charging-outline:before,
.ion-ios-battery-dead:before,
.ion-ios-battery-dead-outline:before,
.ion-ios-battery-full:before,
.ion-ios-battery-full-outline:before,
.ion-ios-beaker:before,
.ion-ios-beaker-outline:before,
.ion-ios-beer:before,
.ion-ios-beer-outline:before,
.ion-ios-bicycle:before,
.ion-ios-bicycle-outline:before,
.ion-ios-bluetooth:before,
.ion-ios-bluetooth-outline:before,
.ion-ios-boat:before,
.ion-ios-boat-outline:before,
.ion-ios-body:before,
.ion-ios-body-outline:before,
.ion-ios-bonfire:before,
.ion-ios-bonfire-outline:before,
.ion-ios-book:before,
.ion-ios-book-outline:before,
.ion-ios-bookmark:before,
.ion-ios-bookmark-outline:before,
.ion-ios-bookmarks:before,
.ion-ios-bookmarks-outline:before,
.ion-ios-bowtie:before,
.ion-ios-bowtie-outline:before,
.ion-ios-briefcase:before,
.ion-ios-briefcase-outline:before,
.ion-ios-browsers:before,
.ion-ios-browsers-outline:before,
.ion-ios-brush:before,
.ion-ios-brush-outline:before,
.ion-ios-bug:before,
.ion-ios-bug-outline:before,
.ion-ios-build:before,
.ion-ios-build-outline:before,
.ion-ios-bulb:before,
.ion-ios-bulb-outline:before,
.ion-ios-bus:before,
.ion-ios-bus-outline:before,
.ion-ios-cafe:before,
.ion-ios-cafe-outline:before,
.ion-ios-calculator:before,
.ion-ios-calculator-outline:before,
.ion-ios-calendar:before,
.ion-ios-calendar-outline:before,
.ion-ios-call:before,
.ion-ios-call-outline:before,
.ion-ios-camera:before,
.ion-ios-camera-outline:before,
.ion-ios-car:before,
.ion-ios-car-outline:before,
.ion-ios-card:before,
.ion-ios-card-outline:before,
.ion-ios-cart:before,
.ion-ios-cart-outline:before,
.ion-ios-cash:before,
.ion-ios-cash-outline:before,
.ion-ios-chatboxes:before,
.ion-ios-chatboxes-outline:before,
.ion-ios-chatbubbles:before,
.ion-ios-chatbubbles-outline:before,
.ion-ios-checkbox:before,
.ion-ios-checkbox-outline:before,
.ion-ios-checkmark:before,
.ion-ios-checkmark-circle:before,
.ion-ios-checkmark-circle-outline:before,
.ion-ios-checkmark-outline:before,
.ion-ios-clipboard:before,
.ion-ios-clipboard-outline:before,
.ion-ios-clock:before,
.ion-ios-clock-outline:before,
.ion-ios-close:before,
.ion-ios-close-circle:before,
.ion-ios-close-circle-outline:before,
.ion-ios-close-outline:before,
.ion-ios-closed-captioning:before,
.ion-ios-closed-captioning-outline:before,
.ion-ios-cloud:before,
.ion-ios-cloud-circle:before,
.ion-ios-cloud-circle-outline:before,
.ion-ios-cloud-done:before,
.ion-ios-cloud-done-outline:before,
.ion-ios-cloud-download:before,
.ion-ios-cloud-download-outline:before,
.ion-ios-cloud-outline:before,
.ion-ios-cloud-upload:before,
.ion-ios-cloud-upload-outline:before,
.ion-ios-cloudy:before,
.ion-ios-cloudy-night:before,
.ion-ios-cloudy-night-outline:before,
.ion-ios-cloudy-outline:before,
.ion-ios-code:before,
.ion-ios-code-download:before,
.ion-ios-code-download-outline:before,
.ion-ios-code-outline:before,
.ion-ios-code-working:before,
.ion-ios-code-working-outline:before,
.ion-ios-cog:before,
.ion-ios-cog-outline:before,
.ion-ios-color-fill:before,
.ion-ios-color-fill-outline:before,
.ion-ios-color-filter:before,
.ion-ios-color-filter-outline:before,
.ion-ios-color-palette:before,
.ion-ios-color-palette-outline:before,
.ion-ios-color-wand:before,
.ion-ios-color-wand-outline:before,
.ion-ios-compass:before,
.ion-ios-compass-outline:before,
.ion-ios-construct:before,
.ion-ios-construct-outline:before,
.ion-ios-contact:before,
.ion-ios-contact-outline:before,
.ion-ios-contacts:before,
.ion-ios-contacts-outline:before,
.ion-ios-contract:before,
.ion-ios-contract-outline:before,
.ion-ios-contrast:before,
.ion-ios-contrast-outline:before,
.ion-ios-copy:before,
.ion-ios-copy-outline:before,
.ion-ios-create:before,
.ion-ios-create-outline:before,
.ion-ios-crop:before,
.ion-ios-crop-outline:before,
.ion-ios-cube:before,
.ion-ios-cube-outline:before,
.ion-ios-cut:before,
.ion-ios-cut-outline:before,
.ion-ios-desktop:before,
.ion-ios-desktop-outline:before,
.ion-ios-disc:before,
.ion-ios-disc-outline:before,
.ion-ios-document:before,
.ion-ios-document-outline:before,
.ion-ios-done-all:before,
.ion-ios-done-all-outline:before,
.ion-ios-download:before,
.ion-ios-download-outline:before,
.ion-ios-easel:before,
.ion-ios-easel-outline:before,
.ion-ios-egg:before,
.ion-ios-egg-outline:before,
.ion-ios-exit:before,
.ion-ios-exit-outline:before,
.ion-ios-expand:before,
.ion-ios-expand-outline:before,
.ion-ios-eye:before,
.ion-ios-eye-off:before,
.ion-ios-eye-off-outline:before,
.ion-ios-eye-outline:before,
.ion-ios-fastforward:before,
.ion-ios-fastforward-outline:before,
.ion-ios-female:before,
.ion-ios-female-outline:before,
.ion-ios-filing:before,
.ion-ios-filing-outline:before,
.ion-ios-film:before,
.ion-ios-film-outline:before,
.ion-ios-finger-print:before,
.ion-ios-finger-print-outline:before,
.ion-ios-flag:before,
.ion-ios-flag-outline:before,
.ion-ios-flame:before,
.ion-ios-flame-outline:before,
.ion-ios-flash:before,
.ion-ios-flash-outline:before,
.ion-ios-flask:before,
.ion-ios-flask-outline:before,
.ion-ios-flower:before,
.ion-ios-flower-outline:before,
.ion-ios-folder:before,
.ion-ios-folder-open:before,
.ion-ios-folder-open-outline:before,
.ion-ios-folder-outline:before,
.ion-ios-football:before,
.ion-ios-football-outline:before,
.ion-ios-funnel:before,
.ion-ios-funnel-outline:before,
.ion-ios-game-controller-a:before,
.ion-ios-game-controller-a-outline:before,
.ion-ios-game-controller-b:before,
.ion-ios-game-controller-b-outline:before,
.ion-ios-git-branch:before,
.ion-ios-git-branch-outline:before,
.ion-ios-git-commit:before,
.ion-ios-git-commit-outline:before,
.ion-ios-git-compare:before,
.ion-ios-git-compare-outline:before,
.ion-ios-git-merge:before,
.ion-ios-git-merge-outline:before,
.ion-ios-git-network:before,
.ion-ios-git-network-outline:before,
.ion-ios-git-pull-request:before,
.ion-ios-git-pull-request-outline:before,
.ion-ios-glasses:before,
.ion-ios-glasses-outline:before,
.ion-ios-globe:before,
.ion-ios-globe-outline:before,
.ion-ios-grid:before,
.ion-ios-grid-outline:before,
.ion-ios-hammer:before,
.ion-ios-hammer-outline:before,
.ion-ios-hand:before,
.ion-ios-hand-outline:before,
.ion-ios-happy:before,
.ion-ios-happy-outline:before,
.ion-ios-headset:before,
.ion-ios-headset-outline:before,
.ion-ios-heart:before,
.ion-ios-heart-outline:before,
.ion-ios-help:before,
.ion-ios-help-buoy:before,
.ion-ios-help-buoy-outline:before,
.ion-ios-help-circle:before,
.ion-ios-help-circle-outline:before,
.ion-ios-help-outline:before,
.ion-ios-home:before,
.ion-ios-home-outline:before,
.ion-ios-ice-cream:before,
.ion-ios-ice-cream-outline:before,
.ion-ios-image:before,
.ion-ios-image-outline:before,
.ion-ios-images:before,
.ion-ios-images-outline:before,
.ion-ios-infinite:before,
.ion-ios-infinite-outline:before,
.ion-ios-information:before,
.ion-ios-information-circle:before,
.ion-ios-information-circle-outline:before,
.ion-ios-information-outline:before,
.ion-ios-ionic:before,
.ion-ios-ionic-outline:before,
.ion-ios-ionitron:before,
.ion-ios-ionitron-outline:before,
.ion-ios-jet:before,
.ion-ios-jet-outline:before,
.ion-ios-key:before,
.ion-ios-key-outline:before,
.ion-ios-keypad:before,
.ion-ios-keypad-outline:before,
.ion-ios-laptop:before,
.ion-ios-laptop-outline:before,
.ion-ios-leaf:before,
.ion-ios-leaf-outline:before,
.ion-ios-link:before,
.ion-ios-link-outline:before,
.ion-ios-list:before,
.ion-ios-list-box:before,
.ion-ios-list-box-outline:before,
.ion-ios-list-outline:before,
.ion-ios-locate:before,
.ion-ios-locate-outline:before,
.ion-ios-lock:before,
.ion-ios-lock-outline:before,
.ion-ios-log-in:before,
.ion-ios-log-in-outline:before,
.ion-ios-log-out:before,
.ion-ios-log-out-outline:before,
.ion-ios-magnet:before,
.ion-ios-magnet-outline:before,
.ion-ios-mail:before,
.ion-ios-mail-open:before,
.ion-ios-mail-open-outline:before,
.ion-ios-mail-outline:before,
.ion-ios-male:before,
.ion-ios-male-outline:before,
.ion-ios-man:before,
.ion-ios-man-outline:before,
.ion-ios-map:before,
.ion-ios-map-outline:before,
.ion-ios-medal:before,
.ion-ios-medal-outline:before,
.ion-ios-medical:before,
.ion-ios-medical-outline:before,
.ion-ios-medkit:before,
.ion-ios-medkit-outline:before,
.ion-ios-megaphone:before,
.ion-ios-megaphone-outline:before,
.ion-ios-menu:before,
.ion-ios-menu-outline:before,
.ion-ios-mic:before,
.ion-ios-mic-off:before,
.ion-ios-mic-off-outline:before,
.ion-ios-mic-outline:before,
.ion-ios-microphone:before,
.ion-ios-microphone-outline:before,
.ion-ios-moon:before,
.ion-ios-moon-outline:before,
.ion-ios-more:before,
.ion-ios-more-outline:before,
.ion-ios-move:before,
.ion-ios-move-outline:before,
.ion-ios-musical-note:before,
.ion-ios-musical-note-outline:before,
.ion-ios-musical-notes:before,
.ion-ios-musical-notes-outline:before,
.ion-ios-navigate:before,
.ion-ios-navigate-outline:before,
.ion-ios-no-smoking:before,
.ion-ios-no-smoking-outline:before,
.ion-ios-notifications:before,
.ion-ios-notifications-off:before,
.ion-ios-notifications-off-outline:before,
.ion-ios-notifications-outline:before,
.ion-ios-nuclear:before,
.ion-ios-nuclear-outline:before,
.ion-ios-nutrition:before,
.ion-ios-nutrition-outline:before,
.ion-ios-open:before,
.ion-ios-open-outline:before,
.ion-ios-options:before,
.ion-ios-options-outline:before,
.ion-ios-outlet:before,
.ion-ios-outlet-outline:before,
.ion-ios-paper:before,
.ion-ios-paper-outline:before,
.ion-ios-paper-plane:before,
.ion-ios-paper-plane-outline:before,
.ion-ios-partly-sunny:before,
.ion-ios-partly-sunny-outline:before,
.ion-ios-pause:before,
.ion-ios-pause-outline:before,
.ion-ios-paw:before,
.ion-ios-paw-outline:before,
.ion-ios-people:before,
.ion-ios-people-outline:before,
.ion-ios-person:before,
.ion-ios-person-add:before,
.ion-ios-person-add-outline:before,
.ion-ios-person-outline:before,
.ion-ios-phone-landscape:before,
.ion-ios-phone-landscape-outline:before,
.ion-ios-phone-portrait:before,
.ion-ios-phone-portrait-outline:before,
.ion-ios-photos:before,
.ion-ios-photos-outline:before,
.ion-ios-pie:before,
.ion-ios-pie-outline:before,
.ion-ios-pin:before,
.ion-ios-pin-outline:before,
.ion-ios-pint:before,
.ion-ios-pint-outline:before,
.ion-ios-pizza:before,
.ion-ios-pizza-outline:before,
.ion-ios-plane:before,
.ion-ios-plane-outline:before,
.ion-ios-planet:before,
.ion-ios-planet-outline:before,
.ion-ios-play:before,
.ion-ios-play-outline:before,
.ion-ios-podium:before,
.ion-ios-podium-outline:before,
.ion-ios-power:before,
.ion-ios-power-outline:before,
.ion-ios-pricetag:before,
.ion-ios-pricetag-outline:before,
.ion-ios-pricetags:before,
.ion-ios-pricetags-outline:before,
.ion-ios-print:before,
.ion-ios-print-outline:before,
.ion-ios-pulse:before,
.ion-ios-pulse-outline:before,
.ion-ios-qr-scanner:before,
.ion-ios-qr-scanner-outline:before,
.ion-ios-quote:before,
.ion-ios-quote-outline:before,
.ion-ios-radio:before,
.ion-ios-radio-button-off:before,
.ion-ios-radio-button-off-outline:before,
.ion-ios-radio-button-on:before,
.ion-ios-radio-button-on-outline:before,
.ion-ios-radio-outline:before,
.ion-ios-rainy:before,
.ion-ios-rainy-outline:before,
.ion-ios-recording:before,
.ion-ios-recording-outline:before,
.ion-ios-redo:before,
.ion-ios-redo-outline:before,
.ion-ios-refresh:before,
.ion-ios-refresh-circle:before,
.ion-ios-refresh-circle-outline:before,
.ion-ios-refresh-outline:before,
.ion-ios-remove:before,
.ion-ios-remove-circle:before,
.ion-ios-remove-circle-outline:before,
.ion-ios-remove-outline:before,
.ion-ios-reorder:before,
.ion-ios-reorder-outline:before,
.ion-ios-repeat:before,
.ion-ios-repeat-outline:before,
.ion-ios-resize:before,
.ion-ios-resize-outline:before,
.ion-ios-restaurant:before,
.ion-ios-restaurant-outline:before,
.ion-ios-return-left:before,
.ion-ios-return-left-outline:before,
.ion-ios-return-right:before,
.ion-ios-return-right-outline:before,
.ion-ios-reverse-camera:before,
.ion-ios-reverse-camera-outline:before,
.ion-ios-rewind:before,
.ion-ios-rewind-outline:before,
.ion-ios-ribbon:before,
.ion-ios-ribbon-outline:before,
.ion-ios-rose:before,
.ion-ios-rose-outline:before,
.ion-ios-sad:before,
.ion-ios-sad-outline:before,
.ion-ios-school:before,
.ion-ios-school-outline:before,
.ion-ios-search:before,
.ion-ios-search-outline:before,
.ion-ios-send:before,
.ion-ios-send-outline:before,
.ion-ios-settings:before,
.ion-ios-settings-outline:before,
.ion-ios-share:before,
.ion-ios-share-alt:before,
.ion-ios-share-alt-outline:before,
.ion-ios-share-outline:before,
.ion-ios-shirt:before,
.ion-ios-shirt-outline:before,
.ion-ios-shuffle:before,
.ion-ios-shuffle-outline:before,
.ion-ios-skip-backward:before,
.ion-ios-skip-backward-outline:before,
.ion-ios-skip-forward:before,
.ion-ios-skip-forward-outline:before,
.ion-ios-snow:before,
.ion-ios-snow-outline:before,
.ion-ios-speedometer:before,
.ion-ios-speedometer-outline:before,
.ion-ios-square:before,
.ion-ios-square-outline:before,
.ion-ios-star:before,
.ion-ios-star-half:before,
.ion-ios-star-half-outline:before,
.ion-ios-star-outline:before,
.ion-ios-stats:before,
.ion-ios-stats-outline:before,
.ion-ios-stopwatch:before,
.ion-ios-stopwatch-outline:before,
.ion-ios-subway:before,
.ion-ios-subway-outline:before,
.ion-ios-sunny:before,
.ion-ios-sunny-outline:before,
.ion-ios-swap:before,
.ion-ios-swap-outline:before,
.ion-ios-switch:before,
.ion-ios-switch-outline:before,
.ion-ios-sync:before,
.ion-ios-sync-outline:before,
.ion-ios-tablet-landscape:before,
.ion-ios-tablet-landscape-outline:before,
.ion-ios-tablet-portrait:before,
.ion-ios-tablet-portrait-outline:before,
.ion-ios-tennisball:before,
.ion-ios-tennisball-outline:before,
.ion-ios-text:before,
.ion-ios-text-outline:before,
.ion-ios-thermometer:before,
.ion-ios-thermometer-outline:before,
.ion-ios-thumbs-down:before,
.ion-ios-thumbs-down-outline:before,
.ion-ios-thumbs-up:before,
.ion-ios-thumbs-up-outline:before,
.ion-ios-thunderstorm:before,
.ion-ios-thunderstorm-outline:before,
.ion-ios-time:before,
.ion-ios-time-outline:before,
.ion-ios-timer:before,
.ion-ios-timer-outline:before,
.ion-ios-train:before,
.ion-ios-train-outline:before,
.ion-ios-transgender:before,
.ion-ios-transgender-outline:before,
.ion-ios-trash:before,
.ion-ios-trash-outline:before,
.ion-ios-trending-down:before,
.ion-ios-trending-down-outline:before,
.ion-ios-trending-up:before,
.ion-ios-trending-up-outline:before,
.ion-ios-trophy:before,
.ion-ios-trophy-outline:before,
.ion-ios-umbrella:before,
.ion-ios-umbrella-outline:before,
.ion-ios-undo:before,
.ion-ios-undo-outline:before,
.ion-ios-unlock:before,
.ion-ios-unlock-outline:before,
.ion-ios-videocam:before,
.ion-ios-videocam-outline:before,
.ion-ios-volume-down:before,
.ion-ios-volume-down-outline:before,
.ion-ios-volume-mute:before,
.ion-ios-volume-mute-outline:before,
.ion-ios-volume-off:before,
.ion-ios-volume-off-outline:before,
.ion-ios-volume-up:before,
.ion-ios-volume-up-outline:before,
.ion-ios-walk:before,
.ion-ios-walk-outline:before,
.ion-ios-warning:before,
.ion-ios-warning-outline:before,
.ion-ios-watch:before,
.ion-ios-watch-outline:before,
.ion-ios-water:before,
.ion-ios-water-outline:before,
.ion-ios-wifi:before,
.ion-ios-wifi-outline:before,
.ion-ios-wine:before,
.ion-ios-wine-outline:before,
.ion-ios-woman:before,
.ion-ios-woman-outline:before,
.ion-logo-android:before,
.ion-logo-angular:before,
.ion-logo-apple:before,
.ion-logo-bitcoin:before,
.ion-logo-buffer:before,
.ion-logo-chrome:before,
.ion-logo-codepen:before,
.ion-logo-css3:before,
.ion-logo-designernews:before,
.ion-logo-dribbble:before,
.ion-logo-dropbox:before,
.ion-logo-euro:before,
.ion-logo-facebook:before,
.ion-logo-foursquare:before,
.ion-logo-freebsd-devil:before,
.ion-logo-github:before,
.ion-logo-google:before,
.ion-logo-googleplus:before,
.ion-logo-hackernews:before,
.ion-logo-html5:before,
.ion-logo-instagram:before,
.ion-logo-javascript:before,
.ion-logo-linkedin:before,
.ion-logo-markdown:before,
.ion-logo-nodejs:before,
.ion-logo-octocat:before,
.ion-logo-pinterest:before,
.ion-logo-playstation:before,
.ion-logo-python:before,
.ion-logo-reddit:before,
.ion-logo-rss:before,
.ion-logo-sass:before,
.ion-logo-skype:before,
.ion-logo-snapchat:before,
.ion-logo-steam:before,
.ion-logo-tumblr:before,
.ion-logo-tux:before,
.ion-logo-twitch:before,
.ion-logo-twitter:before,
.ion-logo-usd:before,
.ion-logo-vimeo:before,
.ion-logo-whatsapp:before,
.ion-logo-windows:before,
.ion-logo-wordpress:before,
.ion-logo-xbox:before,
.ion-logo-yahoo:before,
.ion-logo-yen:before,
.ion-logo-youtube:before,
.ion-md-add:before,
.ion-md-add-circle:before,
.ion-md-alarm:before,
.ion-md-albums:before,
.ion-md-alert:before,
.ion-md-american-football:before,
.ion-md-analytics:before,
.ion-md-aperture:before,
.ion-md-apps:before,
.ion-md-appstore:before,
.ion-md-archive:before,
.ion-md-arrow-back:before,
.ion-md-arrow-down:before,
.ion-md-arrow-dropdown:before,
.ion-md-arrow-dropdown-circle:before,
.ion-md-arrow-dropleft:before,
.ion-md-arrow-dropleft-circle:before,
.ion-md-arrow-dropright:before,
.ion-md-arrow-dropright-circle:before,
.ion-md-arrow-dropup:before,
.ion-md-arrow-dropup-circle:before,
.ion-md-arrow-forward:before,
.ion-md-arrow-round-back:before,
.ion-md-arrow-round-down:before,
.ion-md-arrow-round-forward:before,
.ion-md-arrow-round-up:before,
.ion-md-arrow-up:before,
.ion-md-at:before,
.ion-md-attach:before,
.ion-md-backspace:before,
.ion-md-barcode:before,
.ion-md-baseball:before,
.ion-md-basket:before,
.ion-md-basketball:before,
.ion-md-battery-charging:before,
.ion-md-battery-dead:before,
.ion-md-battery-full:before,
.ion-md-beaker:before,
.ion-md-beer:before,
.ion-md-bicycle:before,
.ion-md-bluetooth:before,
.ion-md-boat:before,
.ion-md-body:before,
.ion-md-bonfire:before,
.ion-md-book:before,
.ion-md-bookmark:before,
.ion-md-bookmarks:before,
.ion-md-bowtie:before,
.ion-md-briefcase:before,
.ion-md-browsers:before,
.ion-md-brush:before,
.ion-md-bug:before,
.ion-md-build:before,
.ion-md-bulb:before,
.ion-md-bus:before,
.ion-md-cafe:before,
.ion-md-calculator:before,
.ion-md-calendar:before,
.ion-md-call:before,
.ion-md-camera:before,
.ion-md-car:before,
.ion-md-card:before,
.ion-md-cart:before,
.ion-md-cash:before,
.ion-md-chatboxes:before,
.ion-md-chatbubbles:before,
.ion-md-checkbox:before,
.ion-md-checkbox-outline:before,
.ion-md-checkmark:before,
.ion-md-checkmark-circle:before,
.ion-md-checkmark-circle-outline:before,
.ion-md-clipboard:before,
.ion-md-clock:before,
.ion-md-close:before,
.ion-md-close-circle:before,
.ion-md-closed-captioning:before,
.ion-md-cloud:before,
.ion-md-cloud-circle:before,
.ion-md-cloud-done:before,
.ion-md-cloud-download:before,
.ion-md-cloud-outline:before,
.ion-md-cloud-upload:before,
.ion-md-cloudy:before,
.ion-md-cloudy-night:before,
.ion-md-code:before,
.ion-md-code-download:before,
.ion-md-code-working:before,
.ion-md-cog:before,
.ion-md-color-fill:before,
.ion-md-color-filter:before,
.ion-md-color-palette:before,
.ion-md-color-wand:before,
.ion-md-compass:before,
.ion-md-construct:before,
.ion-md-contact:before,
.ion-md-contacts:before,
.ion-md-contract:before,
.ion-md-contrast:before,
.ion-md-copy:before,
.ion-md-create:before,
.ion-md-crop:before,
.ion-md-cube:before,
.ion-md-cut:before,
.ion-md-desktop:before,
.ion-md-disc:before,
.ion-md-document:before,
.ion-md-done-all:before,
.ion-md-download:before,
.ion-md-easel:before,
.ion-md-egg:before,
.ion-md-exit:before,
.ion-md-expand:before,
.ion-md-eye:before,
.ion-md-eye-off:before,
.ion-md-fastforward:before,
.ion-md-female:before,
.ion-md-filing:before,
.ion-md-film:before,
.ion-md-finger-print:before,
.ion-md-flag:before,
.ion-md-flame:before,
.ion-md-flash:before,
.ion-md-flask:before,
.ion-md-flower:before,
.ion-md-folder:before,
.ion-md-folder-open:before,
.ion-md-football:before,
.ion-md-funnel:before,
.ion-md-game-controller-a:before,
.ion-md-game-controller-b:before,
.ion-md-git-branch:before,
.ion-md-git-commit:before,
.ion-md-git-compare:before,
.ion-md-git-merge:before,
.ion-md-git-network:before,
.ion-md-git-pull-request:before,
.ion-md-glasses:before,
.ion-md-globe:before,
.ion-md-grid:before,
.ion-md-hammer:before,
.ion-md-hand:before,
.ion-md-happy:before,
.ion-md-headset:before,
.ion-md-heart:before,
.ion-md-heart-outline:before,
.ion-md-help:before,
.ion-md-help-buoy:before,
.ion-md-help-circle:before,
.ion-md-home:before,
.ion-md-ice-cream:before,
.ion-md-image:before,
.ion-md-images:before,
.ion-md-infinite:before,
.ion-md-information:before,
.ion-md-information-circle:before,
.ion-md-ionic:before,
.ion-md-ionitron:before,
.ion-md-jet:before,
.ion-md-key:before,
.ion-md-keypad:before,
.ion-md-laptop:before,
.ion-md-leaf:before,
.ion-md-link:before,
.ion-md-list:before,
.ion-md-list-box:before,
.ion-md-locate:before,
.ion-md-lock:before,
.ion-md-log-in:before,
.ion-md-log-out:before,
.ion-md-magnet:before,
.ion-md-mail:before,
.ion-md-mail-open:before,
.ion-md-male:before,
.ion-md-man:before,
.ion-md-map:before,
.ion-md-medal:before,
.ion-md-medical:before,
.ion-md-medkit:before,
.ion-md-megaphone:before,
.ion-md-menu:before,
.ion-md-mic:before,
.ion-md-mic-off:before,
.ion-md-microphone:before,
.ion-md-moon:before,
.ion-md-more:before,
.ion-md-move:before,
.ion-md-musical-note:before,
.ion-md-musical-notes:before,
.ion-md-navigate:before,
.ion-md-no-smoking:before,
.ion-md-notifications:before,
.ion-md-notifications-off:before,
.ion-md-notifications-outline:before,
.ion-md-nuclear:before,
.ion-md-nutrition:before,
.ion-md-open:before,
.ion-md-options:before,
.ion-md-outlet:before,
.ion-md-paper:before,
.ion-md-paper-plane:before,
.ion-md-partly-sunny:before,
.ion-md-pause:before,
.ion-md-paw:before,
.ion-md-people:before,
.ion-md-person:before,
.ion-md-person-add:before,
.ion-md-phone-landscape:before,
.ion-md-phone-portrait:before,
.ion-md-photos:before,
.ion-md-pie:before,
.ion-md-pin:before,
.ion-md-pint:before,
.ion-md-pizza:before,
.ion-md-plane:before,
.ion-md-planet:before,
.ion-md-play:before,
.ion-md-podium:before,
.ion-md-power:before,
.ion-md-pricetag:before,
.ion-md-pricetags:before,
.ion-md-print:before,
.ion-md-pulse:before,
.ion-md-qr-scanner:before,
.ion-md-quote:before,
.ion-md-radio:before,
.ion-md-radio-button-off:before,
.ion-md-radio-button-on:before,
.ion-md-rainy:before,
.ion-md-recording:before,
.ion-md-redo:before,
.ion-md-refresh:before,
.ion-md-refresh-circle:before,
.ion-md-remove:before,
.ion-md-remove-circle:before,
.ion-md-reorder:before,
.ion-md-repeat:before,
.ion-md-resize:before,
.ion-md-restaurant:before,
.ion-md-return-left:before,
.ion-md-return-right:before,
.ion-md-reverse-camera:before,
.ion-md-rewind:before,
.ion-md-ribbon:before,
.ion-md-rose:before,
.ion-md-sad:before,
.ion-md-school:before,
.ion-md-search:before,
.ion-md-send:before,
.ion-md-settings:before,
.ion-md-share:before,
.ion-md-share-alt:before,
.ion-md-shirt:before,
.ion-md-shuffle:before,
.ion-md-skip-backward:before,
.ion-md-skip-forward:before,
.ion-md-snow:before,
.ion-md-speedometer:before,
.ion-md-square:before,
.ion-md-square-outline:before,
.ion-md-star:before,
.ion-md-star-half:before,
.ion-md-star-outline:before,
.ion-md-stats:before,
.ion-md-stopwatch:before,
.ion-md-subway:before,
.ion-md-sunny:before,
.ion-md-swap:before,
.ion-md-switch:before,
.ion-md-sync:before,
.ion-md-tablet-landscape:before,
.ion-md-tablet-portrait:before,
.ion-md-tennisball:before,
.ion-md-text:before,
.ion-md-thermometer:before,
.ion-md-thumbs-down:before,
.ion-md-thumbs-up:before,
.ion-md-thunderstorm:before,
.ion-md-time:before,
.ion-md-timer:before,
.ion-md-train:before,
.ion-md-transgender:before,
.ion-md-trash:before,
.ion-md-trending-down:before,
.ion-md-trending-up:before,
.ion-md-trophy:before,
.ion-md-umbrella:before,
.ion-md-undo:before,
.ion-md-unlock:before,
.ion-md-videocam:before,
.ion-md-volume-down:before,
.ion-md-volume-mute:before,
.ion-md-volume-off:before,
.ion-md-volume-up:before,
.ion-md-walk:before,
.ion-md-warning:before,
.ion-md-watch:before,
.ion-md-water:before,
.ion-md-wifi:before,
.ion-md-wine:before,
.ion-md-woman:before
{
  @extend .ion;
}